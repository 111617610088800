<template>
	<div></div>
</template>

<script>
export default {
	name: 'ScheduleBoard',
	mounted() {
		const storageStore = sessionStorage.getItem('schedule')
		if (!storageStore) {
			window.close()
			return
		}
		const scheduleInfo = JSON.parse(storageStore)

		const retUrl = `${window.location.protocol}//${window.location.host}/order/schedule`
		const closeUrl = `${window.location.protocol}//${window.location.host}/order/schedule?type=close`

		this.fullPathCompare(this.$route.query)

		const body = document.querySelector('body')
		body.insertAdjacentHTML(
			'beforeend',
			`<form id="frm" name="frm"
						action="${process.env.VUE_APP_API_SCHEDULE_URL}/booking/schedule-board/open"
						method="post" target="_self">
						<input type="hidden" id="zipSeq" name="zipSeq" value="${scheduleInfo.zipSeq}" />
						<input type="hidden" id="jobType" name="jobType" value="INSTALL" />
						<input type="hidden" id="goodsCodeList" name="goodsCodeList" value="${scheduleInfo.goodsCodeList}" />
						<input type="hidden" id="goodsNameList" name="goodsNameList" value="${scheduleInfo.goodsNameList}" />
						<input type="hidden" id="redirectUrl" name="redirectUrl" value="${retUrl}" />
						<input type="hidden" id="closeUrl" name="closeUrl" value="${closeUrl}" />
						<input type="hidden" id="errorUrl" name="errorUrl" value="${closeUrl}" />
						<input type="hidden" id="workerType" name="workerType" value="${scheduleInfo.workerType}" />
						<input type="hidden" id="userId" name="userId" value="${scheduleInfo.userId}" />
						<input type="hidden" id="sessionKey" name="sessionKey" value="" />
						<input type="hidden" id="address" name="address" value="${scheduleInfo.address}" />
						<input type="hidden" id="zipCode" name="zipCode" value="${scheduleInfo.zipCode}" />
						<input type="hidden" id="orderList" name="orderList" value="${scheduleInfo.orderList}" />
						<input type="hidden" id="prodList" name="prodList" value="${scheduleInfo.prodList}" />
				</form>`
		)
		document.getElementById('frm').submit()
	},
	methods: {
		async fullPathCompare(query) {
			// 배정판 일정 선택완료한 경우, 라우트 쿼리에 bookingDate값이 있으면 부모창에 값 전달
			if (query.bookingDate) {
				const schedule = JSON.parse(sessionStorage.getItem('schedule'))
				const params = {
					bookingDate: this.$route.query.bookingDate,
					bookingSlot: this.$route.query.bookingSlot,
					bookingSlotTime: this.$route.query.bookingSlotTime,
					bookingIndex: schedule.index,
					key: query.key,
					address: schedule.address,
					zipCode: schedule.zipCode
				}

				window.opener.$updateDeliveryInfos(params)
				window.close()
			} else if (query.type === 'close') {
				window.close()
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>
